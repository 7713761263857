import { startCase, lowerCase } from "lodash";

export function toTitleCase(title: string) {
    return title.replace("-", " ").split(" ").map(word => startCase(lowerCase(word))).join(" ");
}

export function isNumeric(str?: string) {
    return !isNaN(Number(str));
}

export function formatNumberWithK(num: number) {
    return Math.abs(num) > 999 ? Math.sign(num)*Number.parseFloat((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}