import classNames from 'classnames';
import { CSSProperties, FC } from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

const COLORS = ['fill-sky-700', "fill-rose-700", 'fill-teal-700', 'fill-orange-700', 'fill-lime-700'];

type IPaddedPieChartProps = {
  height: number;
  width: number;
  data: { name: string, value: number }[];
}

export const PaddedPieChart: FC<IPaddedPieChartProps> = ({ height, width, data }) => {
    return (
      <PieChart height={height} width={width}>
        <Pie
          data={data}
          innerRadius={50}
          outerRadius={60}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((_, index) => (
            <Cell className={classNames("stroke-none", COLORS[index % COLORS.length])} key={`cell-${index}`} />
          ))}
        </Pie>
        <Tooltip
          contentStyle={{
            backgroundColor: 'rgba(255,255,255,0.2)',
            borderRadius: "12px",
            border: 0,
            color: "white",
            ...{
              "--tw-backdrop-blur": "blur(16px)",
              "-webkit-backdrop-filter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
              "backdrop-filter": "var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)",
            } as CSSProperties,
           }} 
          itemStyle={{ color: "white" }}
        />
      </PieChart>
    );
}
