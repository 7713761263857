import { cloneElement, FC, useCallback, useState } from "react";
import { ClassNames } from "../../components/classes";
import { Icons } from "../../components/icons";
import { Input } from "../../components/input";
import { Page } from "../../components/page";
import classNames from "classnames";
import { useLoginMutation } from "../../generated/graphql";
import { notify } from "../../store/function";
import { InternalRoutes } from "../../config/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthActions } from "../../store/auth";

export const LoginPage: FC = () => {
    const [code, setCode] = useState("");

    const [login, ] = useLoginMutation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogin = useCallback(() => {
        login({
            variables: {
                credentials: {
                    Token: code,
                },
            },
            onCompleted(data) {
                if (data.Login.Status) {
                    dispatch(AuthActions.login());
                    navigate(InternalRoutes.Brands.Brands.path);
                    return notify("Login successful!", "success");
                }
                notify("Error logging in", "error");
            },
            onError() {
                notify("Error logging in", "error");
            }
        });
    }, [code, dispatch, login, navigate]);

    return <Page className="justify-center items-center relative">
        <div className="z-10 flex justify-center items-center backdrop-blur-md h-full w-full">
            <div className="w-[450px] flex flex-col gap-2">
                <div className={classNames(ClassNames.Title, "text-4xl self-center mb-8")}>
                    Nano Knot
                </div>
                <Input placeholder="Enter vault code" value={code} setValue={setCode} type="password" />
                <button className={classNames(ClassNames.Button, "self-end")} onClick={handleLogin}>
                    <div className="text-lg">Login</div>
                    {Icons.RightArrow}
                </button>
            </div>
        </div>
        {cloneElement(Icons.Lock, {
            className: "absolute w-[50%] h-[50%] stroke-white brightness-[0.15]",
        })}
    </Page>
}