import classNames from "classnames";
import { isArray, max, min } from "lodash";
import { FC, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PaddedPieChart } from "../../components/charts/pie-chart";
import { ClassNames } from "../../components/classes";
import { InternalPage } from "../../components/page";
import { Table } from "../../components/table";
import { InternalRoutes } from "../../config/routes";
import { AnalyticsType, Brand, GetBrandProductsQuery, useGetBrandAnalyticsQuery, useGetBrandProductsQuery } from "../../generated/graphql";
import { formatNumberWithK, isNumeric, toTitleCase } from "../../utils/functions";
import { ProductDetails } from "../product/product-details";

type Product = GetBrandProductsQuery["Product"][0];

export const BrandPage: FC = () => {
  const [productToShow, setProductToShow] = useState<Product>();
  const brand: Brand = useLocation().state?.brand;
  const { data: products } = useGetBrandProductsQuery({
    variables: {
      brandId: brand?.Id,
    },
    skip: brand?.Id == null,
  });
  const { data: analytics } = useGetBrandAnalyticsQuery({
    variables: {
      brandId: brand?.Id,
    },
  });

  const rows = useMemo(() => {
    if (products?.Product == null || products.Product.length === 0) {
      return [];
    }
    return products.Product.map(product => {
      const skusPrice = product.SKUs.map(sku => sku.Price);
      const minPrice = min(skusPrice);
      const maxPrice = max(skusPrice);
      const colors = product.SKUs.reduce((all: Set<string>, one) => {
        (one.Images?.map(image => image.Color) ?? []).forEach(color => all.add(color));
        return all;
      }, new Set<string>());
      return [product.Name, maxPrice == null ? "Unknown" : minPrice === maxPrice ? `${maxPrice}` : `${minPrice} - ${maxPrice}`, product.Description, product.Labels, [...colors.values()]];
    });
  }, [products?.Product]);

  if (brand == null) {
    return <Navigate to={InternalRoutes.Dashboard.path} />
  }

  return <InternalPage routes={[InternalRoutes.Dashboard, {
    ...InternalRoutes.Brands.Brands,
    name: toTitleCase(brand.Name),
  }]}>
    <div className="flex justify-center w-full h-full gap-20 mt-[10vh]">
      <div className="flex flex-col h-[60vh]">
        <div className="flex flex-col w-full gap-4">
          <div className={classNames(ClassNames.Title, "text-2xl")}>
            Quick analysis
          </div>
        </div>
        <div className="flex flex-wrap w-full items-center justify-around">
          {
            analytics?.BrandAnalytics.slice(0, 3).map(analytic => (
              <div className="flex flex-col gap-2 items-center h-fit w-1/3 ">
                { analytic.Type === AnalyticsType.Highlight &&
                    <div className={classNames(ClassNames.Text, "text-[92px]")}>
                      {analytic.Highlight?.Value != null && isNumeric(analytic.Highlight.Value) ? formatNumberWithK(Number.parseFloat(analytic.Highlight.Value)) : analytic.Highlight?.Value}
                    </div>
                }
                {
                  analytic.Type === AnalyticsType.PieChart &&
                  <PaddedPieChart height={150} width={200} data={analytic.PieChart?.Labels?.map((name, i) => ({ name, value: analytic.PieChart?.Values[i] ?? 0 })) ?? []} />
                }
                <div className="flex flex-col items-center justify-center">
                    <div className={classNames(ClassNames.Text, "text-lg")}>{analytic.Title}</div>
                    <div className={classNames(ClassNames.Text, "text-xs")}>{analytic.SubTitle != null && `(${analytic.SubTitle})`}</div>
                </div>
              </div>
            ))
          }
        </div>
        <div className="mt-10 flex flex-col gap-4 w-[75vw]">
          <div className={classNames(ClassNames.Title, "text-2xl")}>
            Explore products
          </div>
          <Table className="h-[75vh] mb-[20vh]" columns={["Name", "Price", "Description", "Tags", "Colors"]} currentPage={0} rows={rows} totalPages={1}
            onClickRow={index => setProductToShow(products?.Product?.[index])}
            customElement={{
              3: (cell) => (
                <div className="px-2 flex gap-1">
                  {isArray(cell.value) ? cell.value.map(label => (
                    <div className="bg-teal-500/50 px-2 py-[2px] w-fit rounded-3xl text-[10px]">{label}</div>
                  )) : null}
                </div>
              ),
              4: (cell) => (
                <div className="px-2 flex gap-1 flex-wrap overflow-y-auto">
                  {isArray(cell.value) ? cell.value.map(label => (
                    <div className="bg-teal-500/50 px-2 py-[2px] rounded-3xl text-[10px]">{label}</div>
                  )) : null}
                </div>
              )
            }} />
        </div>
      </div>
    </div>
    {
      productToShow != null &&
      <ProductDetails brand={brand} product={productToShow} onCancel={() => setProductToShow(undefined)} />
    }
  </InternalPage>
}