import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { forEach, uniq } from "lodash";
import { cloneElement, FC, MouseEvent, useCallback, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import { ClassNames } from "../../components/classes";
import { Icons } from "../../components/icons";
import { Brand, GetBrandProductsQuery, ProductSkuImage } from "../../generated/graphql";
import { GlobalActions } from "../../store/global";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { toTitleCase } from "../../utils/functions";

type Product = GetBrandProductsQuery["Product"][0];

export const ProductDetails: FC<{
    className?: string,
    product: Product,
    brand: Brand,
    onCancel: () => void,
}> = ({ className, product, brand, onCancel }) => {
  const dispatch = useAppDispatch();
  const comparingProducts = useAppSelector(state => state.global.comparingProducts);

  const [zoomedImage, setZoomedImage] = useState<ProductSkuImage>();
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });

  const hasMultiplePrices = useMemo(() => {
    return uniq(product.SKUs.map(sku => sku.Price)).length > 1;
  }, [product.SKUs]);

  const uniqueSKUs = useMemo(() => {
    const skus: GetBrandProductsQuery["Product"][0]["SKUs"] = [];
    forEach(product.SKUs, sku => {
      const seenPaths = new Set<string>();
      const uniqueImages: ProductSkuImage[] = []
      forEach(sku.Images ?? [], (img) => {
        const url = new URL(img.Url);
        if (seenPaths.has(url.pathname)) {
          return;
        }
        seenPaths.add(url.pathname);
        uniqueImages.push({
          Color: img.Color,
          Url: `${url.protocol}//${url.hostname}/${url.pathname}`,
        });
      });
      skus.push({
        ...sku,
        Images: uniqueImages,
      })
    });
    return skus;
  }, [product.SKUs]);

  const existsInComparison = useMemo(() => {
    return comparingProducts.find(comparingProduct => comparingProduct.product.Id === product.Id) != null;
  }, [comparingProducts, product.Id]);

  const handleCompare = useCallback(() => {
    if (existsInComparison) {
      dispatch(GlobalActions.removeComparingProduct({ id: product.Id }));
    } else {
      dispatch(GlobalActions.addComparingProduct({ product: product as any, brand }));
    }
  }, [brand, dispatch, existsInComparison, product]);

  const handleMouseEnter = useCallback((image: ProductSkuImage) => {
    setZoomedImage(image);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setZoomedImage(undefined);
  }, []);

  const handleMouseMove = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const rect = (e.target as HTMLDivElement).getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setZoomPosition({ x: -((x/rect.width)*100 - 50), y: -((y/rect.height)*100 - 50) });
  }, []);

  return <AnimatePresence mode="wait">
    <motion.div className={twMerge(classNames("fixed z-10 top-0 right-0 h-full w-[50vw] shadow-2xl shadow-black dark:bg-black/10 backdrop-blur-lg p-8", className))}
        initial={{ right: "-50vw" }}
        animate={{ right: "0px" }}
        exit={{ right: "-50vw" }}
        onClick={(e) => e.stopPropagation()}>
        {
            product != null &&
            <>
            <div className="fixed top-0 left-0 h-full w-[50vw] overflow-hidden" onClick={onCancel}>
                {
                zoomedImage && (
                    <img
                    className="shadow-2xl shadow-black rounded-lg h-auto"
                    src={zoomedImage.Url}
                    alt="Zoomed product"
                    style={{
                        transform: `translate(${zoomPosition.x*4}%, ${zoomPosition.y*4}%) scale(4)`,
                        transformOrigin: "center center",
                    }}
                    />
                )
                }
            </div>
            <div className="flex flex-col gap-8 h-[100vh] overflow-y-scroll pb-20">
                <div className="flex justify-between items-center w-full">
                <div className={classNames(ClassNames.Text, "text-2xl w-2/3")}>
                    {toTitleCase(product.Name)}
                </div>
                <div className="flex gap-4 items-center">
                    <button className={twMerge(classNames(ClassNames.Button, {
                    "dark:text-green-500": existsInComparison,
                    }))} onClick={handleCompare}>
                    {cloneElement(existsInComparison ? Icons.CheckCircle : Icons.Compare, {
                        className: "w-4 h-4",
                    })}
                    {existsInComparison ? "Added" : "Compare"}
                    </button>
                    <button className={ClassNames.Button} onClick={onCancel}>
                    {cloneElement(Icons.Cancel, {
                        className: "w-4 h-4",
                    })}
                    Close
                    </button>
                </div>
                </div>
                {
                !hasMultiplePrices && <div className={ClassNames.Text}>
                    <strong>Price:</strong> ₹ {product.SKUs?.[0]?.Price?.toLocaleString()}
                </div>
                }
                <div className={classNames(ClassNames.Text, "text-sm")}>
                <div className="mb-2"><strong>Descriptions:</strong><br /></div>
                {product.Description.split(".").map(text => <span>{text}<br /></span>)}
                </div>
                <div className={classNames(ClassNames.Text, "text-sm")}>
                <div className="mb-2"><strong>Compositions:</strong><br /></div>
                {product.Composition.split(".").map(text => <span>{text}<br /></span>)}
                </div>
                <div className="flex flex-wrap flex-col">
                {uniqueSKUs.map((sku, index) => (
                    <div key={`sku-${index}`} className="flex items-center flex-wrap gap-4">
                    {
                        hasMultiplePrices && <div className={classNames(ClassNames.Text, "text-lg")}>
                        <strong>Price:</strong> ₹ {product.SKUs?.[0]?.Price?.toLocaleString()}
                        </div>
                    }
                    <div className="flex items-center flex-wrap gap-4">
                        {
                        sku.Images?.map((image, index) => (
                            <div key={index} className="flex flex-col items-center justify-center"
                                onMouseEnter={() => handleMouseEnter(image)}
                                onMouseLeave={handleMouseLeave}
                                onMouseMove={handleMouseMove}>
                            <img className="rounded-xl h-[250px] w-auto shadow-md shadow-black" alt="Product showcase" src={image.Url} />
                            <div className={classNames(ClassNames.Text, "text-sm")}>{image.Color.replace("Colour:", "")}</div>
                            </div>
                        ))
                        }
                    </div> 
                    </div>
                ))}
                </div>
            </div>
            </>
        }
        </motion.div>
    </AnimatePresence>
}
