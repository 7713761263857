import { FC, useMemo } from "react";
import { ClassNames } from "../classes";
import classNames from "classnames";

type IVerticalDistributionChartProps = {
    data: { name: string, value: number }[];
}

export const VerticalDistributionChart: FC<IVerticalDistributionChartProps> = ({ data }) => {
    const maxValue = useMemo(() => {
        return Math.max(...data.map(datum => datum.value));
    }, [data]);

    return <div className="w-full h-full flex flex-col my-2">
        {
            data.map(datum => (
                <div className="flex bg-white/5">
                    <div className={classNames(ClassNames.Text, "py-2 px-4 w-1/3")}>{datum.name}</div>
                    <div className="py-2 px-4 w-2/3 bg-white/5 flex justify-center items-center">
                        <div className="rounded-full bg-rose-800 h-4 w-4" style={{
                            transform: `scale(${datum.value/maxValue})`,
                        }} />
                    </div>
                </div>
            ))
        }
    </div>
}