import { createSlice } from '@reduxjs/toolkit';

export type IAuthState = {
  status: "logged-in" | "unauthorized";
}

const initialState: IAuthState = {
  status: "unauthorized",
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.status = "logged-in";
    },
    logout: (state) => {
      state.status = "unauthorized";
    },
  },
});

export const AuthActions = authSlice.actions;
export const authReducers = authSlice.reducer;