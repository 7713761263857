import classNames from "classnames";
import { motion } from "framer-motion";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { ClassNames } from "../../components/classes";
import { InternalPage } from "../../components/page";
import { InternalRoutes } from "../../config/routes";
import { GetProductsQuery, useGetProductsLazyQuery } from "../../generated/graphql";
import { ProductDetails } from "./product-details";
import { useNavigate } from "react-router-dom";
import { toTitleCase } from "../../utils/functions";
import { twMerge } from "tailwind-merge";

type Product = GetProductsQuery["Products"][0];

const ProductCard: FC<{ product: Product, onClick?: () => void }> = ({ product, onClick }) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  const handleMouseEnter = useCallback(() => {
    setHover(true)
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div className={classNames(ClassNames.Card, "flex-col relative w-[150px] h-[250px] cursor-pointer overflow-hidden")} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
      onClick={onClick}>
      {product.Brand != null &&
        <div className={twMerge(classNames(ClassNames.Card, "bg-black/20 max-w-[140px] absolute left-2 top-2 transition-all z-10 justify-center items-center cursor-pointer px-2 py-[2px] hover:scale-110"))}
            onClick={() => navigate(InternalRoutes.Brands.Brands.path, {
                state: {
                  brand: product.Brand,
                }
            })}>
            <div className={classNames(ClassNames.Text, "text-xs")}>{toTitleCase(product.Brand.Name)}</div>
        </div>
      }
      { product.SKUs[0]?.Images != null  && <img className="w-full h-full object-cover rounded-2xl" src={product.SKUs[0].Images[0].Url} alt="Product" /> }
      <motion.div className="absolute bottom-0 w-full h-[33%] bg-black/50 backdrop-blur-sm flex flex-col gap-2 px-2 py-2 group/product select-none"
        variants={{
          open: { height: "60%", },
          close: { height: "33%", },
        }}
        animate={hover ? "open" : "close"}>
        <div className={classNames(ClassNames.Text, "text-xs")}>
          {product.Name}
        </div>
        <div className={classNames("opacity-0 duration-500 flex flex-col gap-2", {
          "opacity-100": hover,
        })}>
          <div className={classNames(ClassNames.Text, "text-sm self-start")}>₹ {product.SKUs?.[0]?.Price?.toLocaleString()}</div>
          <button className={classNames(ClassNames.OutlinedButton, "self-start text-xs")}>
            Explore
          </button>
        </div>
      </motion.div>
    </div>
  )
}

export const ProductPage: FC = () => {
  const [productToShow, setProductToShow] = useState<Product | undefined>();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [getProducts, ] = useGetProductsLazyQuery({
    onCompleted(data) {
      setProducts(p => [...p, ...data.Products ?? []]);
    },
  });
  
  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        getProducts({
          variables: {
            pageSize: 50,
            pageOffset: products.length,
          },
        });
      }
    }
  }, [getProducts, products?.length]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    getProducts({
      variables: {
        pageSize: 50,
        pageOffset: 0,
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InternalPage routes={[InternalRoutes.Dashboard, InternalRoutes.Product.Product]}>
      <div className="flex w-full h-full gap-20">
        <div className="flex flex-col h-[90vh]">
          <div className="flex flex-col w-full mb-4">
            <div className={classNames(ClassNames.Title, "text-2xl")}>Products</div>
          </div>
          <div
            ref={containerRef}
            className="flex flex-wrap w-full items-center justify-around gap-2 space-y-2 overflow-y-auto"
            style={{ height: "100%" }}
          >
            {products.map((product) => (
              <ProductCard key={product.Id} product={product} onClick={() => setProductToShow(product)} />
            ))}
          </div>
        </div>
      </div>

      {productToShow != null && productToShow.Brand != null && (
        <ProductDetails
          className="dark:bg-black/70"
          brand={productToShow.Brand}
          product={productToShow}
          onCancel={() => setProductToShow(undefined)}
        />
      )}
    </InternalPage>
  );
};