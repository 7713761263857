import classNames from "classnames";
import { cloneElement, FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ClassNames } from "../../components/classes";
import { Icons } from "../../components/icons";
import { Page } from "../../components/page";
import { useLogoutMutation } from "../../generated/graphql";
import { AuthActions } from "../../store/auth";
import { notify } from "../../store/function";

export const LogoutPage: FC = () => {
  const [logout, ] = useLogoutMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    logout({
      onCompleted() {
        notify("Logged out successfully", "success");
        dispatch(AuthActions.logout());
      },
      onError() {
        notify("Error logging out", "error");
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Page className="flex justify-center items-center">
    <div className="z-10 flex justify-center items-center backdrop-blur-md h-full w-full">
      <div className={classNames(ClassNames.Text, "text-lg self-center")}>
          Logging out
      </div>
    </div>
    {cloneElement(Icons.Lock, {
        className: "absolute w-[50%] h-[50%] stroke-white brightness-[0.15]",
    })}
  </Page>
}