import classNames from "classnames";
import { CSSProperties, FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Cell, Row, useBlockLayout, useTable } from 'react-table';
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { twMerge } from "tailwind-merge";
import { isNumeric } from "../utils/functions";
import { Icons } from "./icons";

type IPaginationProps = {
    pageCount: number;
    currentPage: number;
    onPageChange?: (page: number) => void;
}

const Pagination: FC<IPaginationProps> = ({ pageCount, currentPage, onPageChange }) => {
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 5;

        if (pageCount <= maxVisiblePages) {
            for (let i = 1; i <= pageCount; i++) {
                pageNumbers.push(
                    <div
                        key={i}
                        className={`cursor-pointer p-2 text-sm hover:scale-110 hover:bg-gray-200 rounded-md text-gray-600 ${currentPage === i ? 'bg-gray-300' : ''}`}
                        onClick={() => onPageChange?.(i)}
                    >
                        {i}
                    </div>
                );
            }
        } else {
            const createPageItem = (i: number) => (
                <div
                    key={i}
                    className={classNames("cursor-pointer p-2 text-sm hover:scale-110 hover:bg-gray-200 dark:hover:bg-white/15 rounded-md text-gray-600 dark:text-neutral-300", {
                        "bg-gray-300 dark:bg-white/10": currentPage === i,
                    })}
                    onClick={() => onPageChange?.(i)}
                >
                    {i}
                </div>
            );

            pageNumbers.push(createPageItem(1));

            if (currentPage > 3) {
                pageNumbers.push(
                    <div key="start-ellipsis" className="cursor-default p-2 text-sm text-gray-600 dark:text-neutral-300">...</div>
                );
            }

            const startPage = Math.max(2, currentPage - 1);
            const endPage = Math.min(pageCount - 1, currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(createPageItem(i));
            }

            if (currentPage < pageCount - 2) {
                pageNumbers.push(
                    <div key="end-ellipsis" className="cursor-default p-2 text-sm text-gray-600 dark:text-neutral-300">...</div>
                );
            }

            pageNumbers.push(createPageItem(pageCount));
        }

        return pageNumbers;
    };

    return (
        <div className="flex space-x-2">
            {renderPageNumbers()}
        </div>
    );
};

type ITDataProps = {
    cell: Cell<Record<string, any>>;
    customElement?: (cell: Cell<Record<string, any>>) => ReactElement;
}

const TData: FC<ITDataProps> = ({ cell, customElement }) => {
    const cellRef = useRef<HTMLDivElement>(null);

    const props = useMemo(() => {
        return cell.getCellProps();
    }, [cell]);

    return <div ref={cellRef} {...props} key={props.key}
        className="group/data cursor-pointer transition-all text-xs table-cell border-t border-l last:border-r group-last/row:border-b group-last/row:first:rounded-bl-lg group-last/row:last:rounded-br-lg border-gray-200 dark:border-white/5 p-0"
    >
        <div className="w-full h-full leading-tight focus:outline-none focus:shadow-outline appearance-none transition-all duration-300 border-solid border-gray-200 dark:border-white/5 overflow-hidden whitespace-nowrap select-none text-gray-600 dark:text-neutral-300 dark:group-even/row:bg-white/5 dark:group-hover/row:bg-white/10 flex items-center">
            { customElement?.(cell) ?? <div className="p-2">{cell.value}</div>}
        </div>
    </div>
}

type ITableRow = {
    row: Row<Record<string, any>>;
    style: CSSProperties;
    customElement?: {[index: number]: (cell: Cell<Record<string, any>>) => ReactElement};
    onClick?: () => void;
}

const TableRow: FC<ITableRow> = ({ row, style, customElement = {}, onClick}) => {
    const props = useMemo(() => {
        return row.getRowProps({ style });
    }, [row, style]);

    return (
        <div className="table-row-group text-xs group/row" {...props} key={props.key} onClick={onClick}>
            {
                row.cells.map((cell, i) => (
                    <TData key={cell.getCellProps().key} cell={cell} customElement={customElement[i-1]} />
                ))
            }
        </div>
    )
}

type ITableProps = {
    className?: string;
    columns: string[];
    columnTags?: string[];
    rows: any[][];
    totalPages: number;
    currentPage: number;
    onPageChange?: (page: number) => void;
    customElement?: {[index: number]: (cell: Cell<Record<string, any>>) => ReactElement};
    onClickRow?: (index: number) => void;
}

export const Table: FC<ITableProps> = ({ className, columns: actualColumns, rows: actualRows, columnTags, totalPages, currentPage, onPageChange, customElement, onClickRow }) => {
    const fixedTableRef = useRef<FixedSizeList>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    const [direction, setDirection] = useState<"asc" | "dsc">();
    const [sortedColumn, setSortedColumn] = useState<string>();
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [data, setData] = useState<Record<string, any>[]>([]);

    const columns = useMemo(() => {
        const indexWidth = 50;
        const colWidth = Math.max(((width - indexWidth)/actualColumns.length), 150);
        const cols = actualColumns.map(col => ({
            id: col,
            Header: col,
            accessor: col,
            width: colWidth,
        }));
        cols.unshift({
            id: "#",
            Header: "#",
            accessor: "#",
            width: indexWidth,
        });
        return cols;
    }, [actualColumns, width]);

    useEffect(() => {
        setData(actualRows.map((row, rowIndex) => {
            return row.reduce((all, one, colIndex) => {
                all[actualColumns[colIndex]] = one;
                return all;
            }, { "#": (rowIndex+1).toString() } as Record<string, any>);
        }));
    }, [actualColumns, actualRows]);

    const sortedRows = useMemo(() => {
        if (!sortedColumn) {
            return data;
        }
        const newRows = [...data];
        newRows.sort((a, b) => {
            const aValue = a[sortedColumn];
            const bValue = b[sortedColumn];
            if (isNumeric(aValue) && isNumeric(bValue)) {
                const aValueNumber = Number.parseFloat(aValue);
                const bValueNumber = Number.parseFloat(bValue);
                return direction === 'asc' ? aValueNumber - bValueNumber : bValueNumber - aValueNumber;
            }

            if (aValue < bValue) {
                return direction === 'asc' ? -1 : 1;
            }
            
            if (aValue > bValue) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        return newRows;
    }, [sortedColumn, direction, data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: sortedRows,
        },
        useBlockLayout,
    );

    const handleSort = useCallback((columnToSort: string) => {
        const columnSelectedIsDifferent = columnToSort !== sortedColumn;
        if (!columnSelectedIsDifferent && direction === "dsc") {
            setDirection(undefined);
            return setSortedColumn(undefined);
        }
        setSortedColumn(columnToSort);
        if (direction == null || columnSelectedIsDifferent) {
            return setDirection("asc");
        }
        setDirection("dsc");
    }, [sortedColumn, direction]);

    const handleRenderRow = useCallback(({ index, style }: ListChildComponentProps) => {
        const row = rows[index];
        prepareRow(row);
        return <TableRow key={`row-${row.values[actualColumns[0]]}`} row={row} style={style} customElement={customElement} onClick={onClickRow != null ? () => onClickRow(index) : undefined} />;
    }, [rows, prepareRow, actualColumns, customElement, onClickRow]);

    useEffect(() => {
        if (containerRef.current == null) {
            return;
        }
        const { height, width } = containerRef.current.getBoundingClientRect();
        setHeight(height); 
        setWidth(width);
    }, []);

    return (
        <div className="flex flex-col grow gap-4 items-center w-full h-full" ref={containerRef}>
            <div className={twMerge(classNames("flex overflow-x-auto h-full", className))} style={{
                width,
            }}>
                <div className="border-separate border-spacing-0 h-fit" ref={tableRef} {...getTableProps()}>
                    <div>
                        {headerGroups.map(headerGroup => (
                            <div {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                                {headerGroup.headers.map((column, i) => (
                                    <div {...column.getHeaderProps()} key={column.getHeaderProps().key} className="text-xs border-t border-l last:border-r border-gray-200 dark:border-white/5 p-2 text-left bg-gray-500 dark:bg-white/5 text-white first:rounded-tl-lg last:rounded-tr-lg relative group/header cursor-pointer select-none"
                                        onClick={() => handleSort(column.id)}>
                                        {column.render('Header')} {i > 0 && columnTags?.[i-1] != null && columnTags?.[i-1].length > 0 && <span className="text-[11px]">[{columnTags?.[i-1]}]</span>}
                                        <div className={twMerge(classNames("transition-all absolute top-2 right-2 opacity-0", {
                                            "opacity-100": sortedColumn === column.id,
                                            "rotate-180": direction === "dsc",
                                        }))}>
                                            {Icons.ArrowUp}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="tbody" {...getTableBodyProps()}>
                        <FixedSizeList
                            ref={fixedTableRef}
                            height={height}
                            itemCount={sortedRows.length}
                            itemSize={100}
                            width="100%"
                        >
                            {handleRenderRow}
                        </FixedSizeList>
                    </div>
                </div>
            </div>
            {
                totalPages > 1 &&
                <div className="flex justify-center items-center">
                    <Pagination pageCount={totalPages} currentPage={currentPage} onPageChange={onPageChange} />
                </div>
            }
        </div>
    )
}
