import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Brand, Product } from '../generated/graphql';

export type BrandProductPair = { product: Product, brand: Brand };

type IGlobalState = {
  theme: "light" | "dark";
  comparingBrands: Brand[];
  comparingProducts: BrandProductPair[];
}

const initialState: IGlobalState = {
    theme: "dark",
    comparingBrands: [],
    comparingProducts: [],
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<IGlobalState["theme"]>) => {
        state.theme = action.payload;
    },
    addComparingProduct: (state, action: PayloadAction<{ product: Product, brand: Brand }>) => {
      if (state.comparingProducts.find(comparingProduct => comparingProduct.product.Id === action.payload.product.Id) != null) {
        return;
      }
      state.comparingProducts.push(action.payload);
    },
    removeComparingProduct: (state, action: PayloadAction<{ id: string }>) => {
      state.comparingProducts = state.comparingProducts.filter(({ product }) => product.Id !== action.payload.id);
    },
    removeAllProducts: (state) => {
      state.comparingProducts = [];
    },
    setAllComparingProducts: (state, action: PayloadAction<BrandProductPair[]>) => {
      state.comparingProducts = action.payload;
    },
    addComparingBrand: (state, action: PayloadAction<Brand>) => {
      if (state.comparingBrands.find(comparingBrand => comparingBrand.Id === action.payload.Id) != null) {
        return;
      }
      state.comparingBrands.push(action.payload);
    },
    removeComparingBrand: (state, action: PayloadAction<{ id: string }>) => {
      state.comparingBrands = state.comparingBrands.filter(brand => brand.Id !== action.payload.id);
    },
    removeAllBrands: (state) => {
      state.comparingBrands = [];
    },
    setAllComparingBrands: (state, action: PayloadAction<{ brands: Brand[] }>) => {
      state.comparingBrands = action.payload.brands;
    },
  },
});

export const GlobalActions = globalSlice.actions;
export const globalReducers = globalSlice.reducer;